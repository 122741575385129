import React from 'react';
import './App.css';
import profileImage from './images/profile-image.jpg';
import linkedinLogo from './images/61109.png'
import githubLogo from './images/25231.png'
import twitterLogo from './images/60580.png'
import agentHackathon from './images/Agent Hackathon.jpg'
import betaCamp from './images/BETACamp.jpg'
import exploreNYC from './images/Explore NYC.jpg'
import mircrosoftHQ from './images/headshotlandscape.jpg'
import jerseryShore from './images/jerseryShore.jpg'
import raptors from './images/raptors.jpg'
import sriMe from './images/sriAndMe.jpg'
import tobiasMe from './images/Tobias and I.jpg'
import montreal from './images/mtl.jpg'

function App() {
  return (
    <>
    <div>
    <nav className="top-navbar">
        <ul>
          <li><a href="https://www.abhayratti.com/">Home</a></li>
          <li><a href="https://abhayratti.notion.site/Hey-I-m-Abhay-78c0f96f5eb740019ac4255c20d6ee63?pvs=4" target='_blank'>Portfolio</a></li>
          <li><a href="https://drive.google.com/file/d/1LaxFLd9C2IXxgCPkpWKHxVta_2PYVN89/view?usp=sharing" target='_blank'>Resume</a></li>
        </ul>
      </nav>
    </div>
    <div className="container">
      <div className="content">
        <div className="header">
          <h1 className="name color-heading">Abhay Ratti</h1>
          <div className="profile-header">
            <img src={profileImage} alt="Profile" className="profile-image" />
            <nav className='social-button'>
              <a href="https://www.linkedin.com/in/abhayratti/" target='_blank'>
                <img src={linkedinLogo} style={{ marginRight: '8px'}}/>
                LinkedIn</a>
              <a href="https://github.com/abhayratti" target='_blank'>
                <img src={githubLogo} style={{ marginRight: '8px'}}/>
                GitHub</a>
              <a href="https://twitter.com/AbhayRatti" target='_blank'>
                <img src={twitterLogo} style={{ marginRight: '8px'}}/>
                Twitter</a>
            </nav>
          </div>
        </div>
        <div className="sections">
          <section>
            <h2 className='color-heading'>Hey, I'm Abhay</h2>
            <p>
              I study Mechatronics Engineering at the University of Waterloo. 
            </p>
            <p>
              I'm primarily interested in manufacturing and robotics. Currently, my project work is centered around chip fabrication and automated robots.  
            </p>
          </section>
          <section>
            <h2 className='color-heading'>Previously</h2>
            <ul>
              <li>Building the Waterloo Hacker Fab, a DIY semiconductor fab built from low-cost components and open-sourced designs</li>
              <li>Created an automated lathe machine that tracks a line profile and cuts the shape into a piece of foam for my 1A design project</li>
              <li>Made an AI agent that applies to hundreds of jobs while you sleep and won Waterloo's AI Agent Hackathon</li>
              <li>Built a multi-modal (VLM) system to help visually impaired individuals navigate their environment @ Hack The North 2023</li>
              <li>Top 32 finalist in Buildspace S4 (out of 17,000) for a farming management platform, Cultura</li>
              <li>Made a rocket that flew 100+ ft in the air</li>
              <li>Built image segmentation models as an ML Intern @ Kevares</li>
              <li>Replicated a bunch of low-level ML projects while I was learning PyTorch</li>
              <li>Worked on $800 million problems as an intern @ Walmart Blue Labs</li>
              <li>Made some Web3 apps back when it was the hype</li>
            </ul>
            <p>
              Check out my <a href="https://abhayratti.notion.site/Hey-I-m-Abhay-78c0f96f5eb740019ac4255c20d6ee63?pvs=4" target='_blank'>portfolio</a> to learn more.
            </p>
          </section>
          <section>
            <h2 className='color-heading'>Fun Facts</h2>
            <ul>
              <li>Attended the Explore Fellowship & spent a week camping in NYC/Connecticut with 40+ super talented high schoolers</li>
              <li>Visited the Microsoft HQ in Toronto (2x)</li>
              <li>A random Twitter DM when I was 16 led to a fully sponsored trip to NFT NYC</li>
              <li>150+ Victory Royales in Fortnite</li>
            </ul>
          </section>
          <section>
            <h2 className='color-heading'>Photos</h2>
            <div class='image-grid'>
              <img src={betaCamp}/>
              <img src={exploreNYC}/>
              <img src={mircrosoftHQ}/>
              <img src={agentHackathon}/>
              <img src={sriMe}/>
              <img src={tobiasMe}/>
              <img src={jerseryShore}/>
              <img src={raptors}/>
              <img src={montreal}/>
            </div>
          </section>
        </div>
      </div>
    </div>
    </>
  );
}

export default App;